const API = {
  //华美接口
  uoloadFiles: 'reports/upload-export-img', // 上传图片生成报告
  uoloadFilesCurId: 'reports/upload-export-img-curid', // 上传图片生成报告demo
  uoloadFilesCurIdReview: 'reports/upload-export-img-curid-review', // 上传图片生成报告-带有图片审核
  uploadFilesUserData: 'reports/upload-export-img-userdata', // 上传图片和用户信息生成报告
  uploadPdf: 'reports/upload-export-pdf', // 上传pdf生成报告
  uploadPdfUserData: 'reports/upload-export-pdf-userdata', // 上传pdf和用户信息生成报告
  uploadPdfCurId: 'reports/upload-export-pdf-curid', // 上传pdf生成报告demo
  getReportData: 'reports/get-report-data', //获得报告列表
  getReportDataUid: 'reports/get-report-data-curid', //根据用户id获取报告链接
  getReportDownloadUrl: 'reports/get-report-download-url', //根据手机号和task id获取报告url
  uploadPsyPdf: 'psyexam/upload-ori-file-pdf', //体检报告上传PDF
  uploadPsyPdfPhone: 'psyexam/upload-ori-file-pdf-phone', //体检报告上传PDF-带渠道和手机号
  uploadPsyPdfPhoneDocDetail: 'psyexam/upload-pdf-phone-doc-detail', //体检报告上传PDF-带渠道和手机号-详细推荐医生
  uploadPsyImgPhoneDocDetail: 'psyexam/upload-img-phone-doc-detail', //体检报告上传IMG-带渠道和手机号-详细推荐医生
  uploadPsyImg: 'psyexam/upload-export-img', //体检报告上传IMG
  getPsyReportData: 'psyexam/get-report-data', //获取报告内容
  getHosPsyReportData: 'psyexam/hos-psy-report-data', //获取报告内容-hos
  getPsyReportInitProgress: 'psyexam/get-report-init-progress', //获取体检报告生成进度
  getMedReportInitProgress: 'reports/get-report-init-progress', //获取报告生成进度
  validateResources: 'user-resource/web-resources-validate', //校验权限
  chnMedUploadImgsCurId: 'chn-reports/upload-export-img-curid', // 中医问诊-上传图片生成报告
  chnMedUploadImgsCurIdNew: 'chn-reports/upload-export-img-ac', // 中医问诊-上传图片生成报告-新版
  chnMedUploadPdfCurId: 'chn-reports/upload-export-pdf-curid', // 中医问诊-上传pdf生成报告
  chnMedUploadPdfCurIdNew: 'chn-reports/upload-export-pdf-ac', // 中医问诊-上传pdf生成报告-新版
  getChnMedReportInitProgress: 'chn-reports/get-report-init-progress', //获取报告生成进度
  getChnMedReportInitProgressNew: 'chn-reports/get-report-init-progress-ac', //获取报告生成进度-新版
  getChnMedReportData: 'chn-reports/get-report-data', //获取报告内容
  getChnMedReportDataIuc: 'chn-reports/get-report-data-iuc', //获取报告内容-根据taskId、uid、cId
  getChnMedReportDataNew: 'chn-reports/get-report-data-ac', //获取报告内容-新版
  chnMedUploadImgsWithValid: 'chn-reports/upload-export-img-src', //上传图片生成报告（带验证信息）
  chnMedUploadPdfsWithValid: 'chn-reports/upload-export-pdf-src', //上传pdf生成报告（带验证信息）
  getChnMedReportInitProgressWithValid:
    'chn-reports/get-report-init-progress-src', //获取报告生成进度（带验证信息）
  getReportDataWithValid: 'chn-reports/get-report-data-src', //获取报告内容（带校验信息）
  getChnUploadRecord: 'chn-reports/list-ac', //获取上传记录

  login: 'logins/login-password',
  sendCode: 'logins/send-check-code', //发送短信验证码
  loginByPhone: 'logins/login-with-code', //手机号验证码登录
  checkToken: 'logins/validate-access-token', //校验token
  complain: 'logins/complaint', //提交用户投诉

  //上传文件生成报告
  uploadImageNew: 'reports/upload-export-img-src', //上传图片报告（新的）
  uploadPdfNew: 'reports/upload-export-pdf-src', //上传图片报告（新的）
  getReportInitProgressNew: 'reports/get-report-init-progress-src',
  getReportContentNew: 'reports/get-premed-report-content-src',

  //体检报告
  getPsyExamUploadImage: 'psyexam/upload-export-img-src', //上传图片生成报告
  getPsyExamUploadPdf: 'psyexam/upload-export-pdf-src', //上传PDF生成报告
  getPsyExamReportInitProgress: 'psyexam/get-report-init-progress-src', //获取生成报告进度
  getPsyExamReportResult: 'psyexam/get-report-data-src', //获取报告结果内容

  //健康饮食
  dietUploadImgCurId: 'diet-reports/upload-export-img-curid', // 健康饮食-上传pdf生成报告
  dietUploadImgCurIdDemo: 'diet-reports/upload-export-img-curid', // 健康饮食-上传pdf生成报告
  getDietReportInitProgress: 'diet-reports/get-report-init-progress', //获取报告生成进度
  getDietReportData: 'diet-reports/get-report-data', //获取报告内容
  getTipsData: 'diet-reports/get-health-tips', //获取健康提示
  getReportData: 'diet-reports/get-report-data', //获取报告内容

  //华医生流式聊天
  chatWithDoctorhua: 'chat/completions',
  chatWithDoctorhuaRag: 'chat/completions-rag',
  //获取服务列表
  getServiceList: 'healthmanage/user-service-codes',
  //获取服务页面地址
  getServiceUrl: 'healthmanage/service-url',
  //发送验证码
  sendCodeForDrhua: 'logins/send-code',
  //验证码登录
  loginWithCodeForDrhua: 'logins/login-code',
  //注册
  registeForDrhua: 'logins/register',
  //修改密码
  changePwdForDrhua: 'logins/update-password',

  //生成推荐内容
  getRecommendContent: 'chat/generate-recommend-content',
  //查询华医生推荐进度
  getRecommendProgress: 'chat/get-recommend-progress',

  //获取常见病问题
  getCommonDisease: 'chat-context/common-disease-questions',

  //获取oss临时token
  getOssUploadToken: 'file/get-upload-token-data',

  //精准就医
  precisionUploadImage: 'reports/upload-export-img-ac', //上传图片生成报告
  precisionReportInitProgress: 'reports/get-report-init-progress-ac', //获取生成报告进度
  precisionUploadPdf: 'reports/upload-export-pdf-ac', //上传PDF生成报告
  getUploadRecord: 'reports/report-list', //获取上传记录
  getReportContent: 'reports/get-report-data-ac', //获取报告内容
  getReportContentV2: 'reports/get-report-download-url-data', //获取报告内容 - 替换旧版本download接口
  getReportContentIuc: 'reports/get-report-data-iuc', //获取报告内容 - 通过taskId、uid、cId获取

  // 体检报告解读
  psyexamReportList: 'psyexam/report-list', // 获取上传记录-带分页
  pspexamUploadImgAc: 'psyexam/upload-img-ac', // 上传图片生成报告-来自应用中心
  pspexamGetReportDataAc: 'psyexam/get-report-data-ac', // 查询体检报告信息
  pspexamUploadPdfAc: 'psyexam/upload-pdf-ac', // 上传pdf生成报告-来自应用中心

  //讯飞
  getIflyVoice: 'chat-context/iat-url', //获取讯飞语音转写

  // OCR识别图片文字内容
  queryImgTOText: 'chat-context/img-to-text', //通过图片地址获取图片文字内容

  // 病历分析
  queryCaseAnanlysisProcess: 'medical-case/report-progress', // 病历分析提取信息获取进度
  queryCaseAnanlysisResult: 'medical-case/case-result', // 病历分析提取信息获取结果
  submitCaseAnanlysisTask: 'medical-case/submit-task', // 提交病例分析
  queryCaseAnanlysisRecord: 'medical-case/case-list', // 获取病例分析历史记录列表-分页

  // 关键医学信息提取
  queryKeyMedicalInforExtractionResult: 'medical-info/result', //  关键医学信息提取 提取信息获取结果
  submitKeyMedicalInforExtractionTask: 'medical-info/analysis', // 提交关键医学信息提取
  queryKeyMedicalInforExtractionRecord: 'medical-info/list', // 获取 关键医学信息提取 历史记录列表-分页

  // 健康饮食
  queryHealthDietRecordList: 'diet-reports/report-list', // 获取上传记录列表
  queryHealthDietReport: 'diet-reports/get-report-data-ac', // 获取报告内容
  queryHealthDietProcess: 'diet-reports/get-report-init-progress-ac', // 获取报告生成进度
  submitHealthDietImage: 'diet-reports/upload-img-ac', // 上传图片开始分析
  getHealthDietTips: 'diet-reports/get-health-tips-ac', // 获取健康提示
  //对话生成病例
  getAnalysisRecord: 'dialog-aly/list', //获取分析记录
  startAnalysis: 'dialog-aly/analysis', //开始分析对话内容
  getAnalysisProgress: 'dialog-aly/progress', //获取分析进度
  getAnalysisResult: 'dialog-aly/result', //获取分析结果
  // 推荐检查检验
  queryRecommedInspectResult: 'medical-info/recommend-inspect-result', // 获取推荐检查检验结果
  submitRecommedInspect: 'medical-info/recommend-inspect', // 提交推荐检查检验
  queryRecommedInspectRecord: 'medical-info/recommend-inspect-list', // 获取 推荐检查检验 历史记录列表-分页
  // 舌诊面诊
  submitFaceTongueImage: 'facetongue-reports/create', // 上传图片开始分析
  queryFaceTongueProcess: 'facetongue-reports/progress', // 获取报告生成进度
  queryFaceTongueResult: 'facetongue-reports/result', // 获取报告内容
  queryFaceTongueRecordList: 'facetongue-reports/list', // 获取上传记录列表
}
export default API
