import { lazy } from 'react'

const Signin = lazy(() => import('../pages/signin'))
const ReportChannel = lazy(() => import('../pages/report_channel'))
const ReportChannelPhone = lazy(() => import('../pages/report_channel_phone'))
const ReportChannelEncrypt = lazy(() =>
  import('../pages/report_channel_encrypt')
)
const ReportChannelPublicHeader = lazy(() =>
  import('../pages/report_channel_publicheader')
)
const ReportChannelUserData = lazy(() =>
  import('../pages/report_channel_userdata')
)

//华美
const Longin = lazy(() => import('../pages/login'))
const Complain = lazy(() => import('../pages/complain_page/index.js'))
const ReportDataList = lazy(() => import('../pages/report_list'))
const Demo = lazy(() => import('../pages/demo'))
const DemoFoot = lazy(() => import('../pages/demo_ai_foot'))
const ReportAnalyst = lazy(() =>
  import('../pages/report_channel_encrypt_callback')
)
const ReportResult = lazy(() => import('../pages/report_result'))
// const ReportResult = lazy(() =>
//   import('../pages/report_result/guideline_report')
// )

// const DownloadReport = lazy(() => import('../pages/down_report'))
const DownloadReport = lazy(() =>
  import('../pages/down_report/ReportDetail.js')
)
const DownloadReportPdf = lazy(() =>
  import('../pages/down_report_pdf/ReportDetail.js')
)
const ErrorPage = lazy(() => import('../pages/error_page'))
const ChnErrorPage = lazy(() => import('../pages/chn_error_page'))
// const PhysicalEaxmReport = lazy(() => import('../pages/physical_examination_report'))
const PhysicalEaxmReport = lazy(() =>
  import('../pages/physical_examination_report')
)
// 固定展示结果，带有中医建议
const PhysicalEaxmReportChnMedFix = lazy(() =>
  import('../pages/physical_examination_report_chn_advice_fix')
)
const PhysicalEaxmReportUploadChnMedFix = lazy(() =>
  import('../pages/physical_examination_upload_chn_advice_fix')
)
// 真实展示结果，带有中医建议
const PhysicalEaxmReportChnMed = lazy(() =>
  import('../pages/physical_examination_report_chn_advice')
)
const PhysicalEaxmReportUploadChnMed = lazy(() =>
  import('../pages/physical_examination_upload_chn_advice')
)

const PhysicalEaxmReportCallback = lazy(() =>
  import('../pages/physical_examination_report_callback')
)
const PhysicalEaxmReportHos = lazy(() =>
  import('../pages/physical_examination_report_enc_id_hos')
)
const PhysicalEaxmReportUpload = lazy(() =>
  import('../pages/physical_examination_upload')
)
const PhysicalEaxmUploadCallBack = lazy(() =>
  import('../pages/physical_examination_upload_callback')
)
const PhysicalEaxmReportPhoneUpload = lazy(() =>
  import('../pages/physical_examination_phone_test_upload')
)
const ChnMedReportUpload = lazy(() => import('../pages/chn_med_report_upload'))
const ChnMedReportView = lazy(() => import('../pages/chn_med_report_view'))
const ChnMedReportUploadFix = lazy(() =>
  import('../pages/chn_med_report_upload_fix')
)
const ChnMedReportViewFix = lazy(() =>
  import('../pages/chn_med_report_view_fix')
)
const ChnMedReportUploadEncsrc = lazy(() =>
  import('../pages/chn_med_report_upload_encsrc')
)
const ChnMedReportViewEncsrc = lazy(() =>
  import('../pages/chn_med_report_view_encsrc')
)
const ChnMedReportViewPdf = lazy(() =>
  import('../pages/chn_med_report_view_pdf')
)
const ChnMedReportUploadNew = lazy(() =>
  import('../pages/chn_med_report_upload_new')
)
const ChnMedReportViewNew = lazy(() =>
  import('../pages/chn_med_report_view_new')
)
const ChnMedReportViewFixPdf = lazy(() =>
  import('../pages/chn_med_report_view_fixpdf/index.js')
)

//健康饮食
const Diet = lazy(() => import('../pages/diet'))
const DietReport = lazy(() => import('../pages/diet_report'))
const DietDemo = lazy(() => import('../pages/diet_demo'))
const DietReportDemo = lazy(() => import('../pages/diet_report_demo'))
const TakePhoto = lazy(() => import('../pages/take_photo/index.js'))
const Identifying = lazy(() => import('../pages/identifying/index.js'))
const DietReportDetail = lazy(() => import('../pages/diet_report_detail'))

//华医生
const doctorhuaIndex = lazy(() => import('../pages/doctorhua/index/index'))
const doctorhuaChat = lazy(() => import('../pages/doctorhua/chat/index'))
const intelligentCall = lazy(() =>
  import('../pages/doctorhua/intelligent_call/index')
)
const historyRecord = lazy(() =>
  import('../pages/doctorhua/history_record/index')
)

//心理治疗
const psychoChat = lazy(() => import('../pages/psychotherapy/chat/index'))
const psychoHistoryRecord = lazy(() =>
  import('../pages/psychotherapy/history_record/index')
)
const psychoCall = lazy(() =>
  import('../pages/psychotherapy/psycho_call/index')
)

//智能医生
const smartDoctorChat = lazy(() =>
  import('../pages/smart_doctor/chat/index.js')
)
const smartDoctorHistoryRecord = lazy(() =>
  import('../pages/smart_doctor/history_record/index.js')
)
const smartDoctorCall = lazy(() =>
  import('../pages/smart_doctor/intelligent_call/index.js')
)

//常见病知识解答
const commonDiseaseChat = lazy(() =>
  import('../pages/common_disease/chat/index.js')
)
const commonDiseaseHistoryRecord = lazy(() =>
  import('../pages/common_disease/history_record/index.js')
)
const commonDiseaseCall = lazy(() =>
  import('../pages/common_disease/intelligent_call/index.js')
)

//AI药店执业药师
const pharmacistChat = lazy(() => import('../pages/pharmacist/chat/index.js'))
const pharmacistHistoryRecord = lazy(() =>
  import('../pages/pharmacist/historyRecord/index.js')
)
const pharmacistCall = lazy(() =>
  import('../pages/pharmacist/intelligentCall/index.js')
)

//智能导诊
const guidanceChat = lazy(() => import('../pages/guidance/chat/index.js'))
const guidanceHistoryRecord = lazy(() =>
  import('../pages/guidance/historyRecord/index.js')
)
const guidanceCall = lazy(() =>
  import('../pages/guidance/intelligentCall/index.js')
)

//药品知识
const drugKnowledgeChat = lazy(() =>
  import('../pages/drug_knowledge/chat/index.js')
)
const drugKnowledgeHistoryRecord = lazy(() =>
  import('../pages/drug_knowledge/history_record/index.js')
)
const drugKnowledgeCall = lazy(() =>
  import('../pages/drug_knowledge/intelligent_call/index.js')
)

// 体检报告解读小助手
const PhyexamAssistant = lazy(() => import('../pages/phyexam/talk/index.js'))
const PhyexamAssistantProgress = lazy(() =>
  import('../pages/phyexam/progress/index.js')
)
const PhyexamAssistantReport = lazy(() =>
  import('../pages/phyexam/report/index.js')
)

// 精准医疗
const PrecisionMedicine = lazy(() =>
  import('../pages/precision_medicine/talk/index.js')
)
const PrecisionMedicineConfirmUpload = lazy(() =>
  import('../pages/precision_medicine/confirm_upload/index.js')
)
const Analysis = lazy(() =>
  import('../pages/precision_medicine/analysis/index.js')
)
const GuidelineReport = lazy(() =>
  import('../pages/precision_medicine/guideline_report/index.js')
)

// web 精准医疗
const WebPrecisionMedicine = lazy(() =>
  import('../pages/web_precision_medicine/index.js')
)
const WebPrecisionMedicineReport = lazy(() =>
  import('../pages/web_precision_medicine/report.js')
)

//对话生成病例
const DialogGenerationCase = lazy(() =>
  import('../pages/dialog_generation_case/index.js')
)

// 病例分析
const CaseAnalysis = lazy(() => import('../pages/case_analysis/index.js'))
// 关键医学信息提取
const KeyMedicalInformationExtraction = lazy(() =>
  import('../pages/key_medical_information_extraction/index.js')
)
// 推荐检查检验
const RecommendInspect = lazy(() =>
  import('../pages/recommend_inspect/index.js')
)
// 健康饮食
const HealthyDiet = lazy(() => import('../pages/healthy_diet/index.js'))
const HealthyDietIdentifying = lazy(() =>
  import('../pages/healthy_diet/identifying.js')
)
const HealthyDietReport = lazy(() => import('../pages/healthy_diet/report.js'))

//华医生web端
const DoctorhuaWeb = lazy(() => import('../pages/doctorhua_web/index.js'))
const DoctorhuaIntro = lazy(() => import('../pages/doctorhua_web/Introduce.js'))
const DoctorhuaChat = lazy(() => import('../pages/doctorhua_web/Chat.js'))

// 面诊舌诊
const FaceAndRongueExamination = lazy(() =>
  import('../pages/face_and_rounge_examination/index.js')
)
const FaceAndRongueExaminationTakePhoto = lazy(() =>
  import('../pages/face_and_rounge_examination/takePhoto.js')
)
const FaceAndRongueExaminationProgress = lazy(() =>
  import('../pages/face_and_rounge_examination/process.js')
)
const FaceAndRongueExaminationReport = lazy(() =>
  import('../pages/face_and_rounge_examination/report.js')
)
const FaceAndRongueExaminationReportPrint = lazy(() =>
  import('../pages/face_and_rounge_examination/reportPrint.js')
)

//华医生提取关键字跳转应用演示版本
const DoctorhuaLinkChat = lazy(() =>
  import('../pages/doctorhua_link/chat/index.js')
)
const DoctorhuaLinkRecord = lazy(() =>
  import('../pages/doctorhua_link/history_record/index.js')
)
const DoctorhuaLinkIndex = lazy(() =>
  import('../pages/doctorhua_link/index/index.js')
)
const LoginDrhua = lazy(() => import('../pages/login_drhua/index.js'))
const RegisterDrhua = lazy(() => import('../pages/login_drhua/Register.js'))
const ChangePwdDrhua = lazy(() => import('../pages/login_drhua/ChangePwd.js'))

const Router = [
  //华美

  {
    path: '/login',
    name: '登录页面',
    component: Longin,
  },
  {
    path: '/complainPage',
    name: '投诉页面',
    component: Complain,
  },
  //短信接收
  {
    path: '/precision_report',
    name: '精准医疗',
    component: ReportChannelPhone,
  },
  //短信接收，带验证
  {
    path: '/precision_report_enc',
    name: '精准医疗',
    component: ReportChannelEncrypt,
  },
  {
    path: '/report_list',
    name: '我的报告列表',
    component: ReportDataList,
  },
  //下载文件
  {
    path: '/demo',
    name: '精准医疗',
    component: Demo,
  },
  //算法备案用加了注脚的页面，下载文件
  {
    path: '/aipre',
    name: '精准医疗',
    component: DemoFoot,
  },
  //带详情结果展示
  {
    path: '/precision_report_enc_cb',
    name: '精准医疗',
    component: ReportAnalyst,
  },

  {
    path: '/report-result',
    name: '报告结果',
    component: ReportResult,
  },
  // 平台试用
  {
    path: '/demomodel',
    name: '精准医疗',
    component: Demo,
  },
  {
    path: '/fortest',
    name: '精准医疗',
    component: Demo,
  },
  {
    path: '/channel',
    name: '精准医疗',
    component: Signin,
  },
  {
    path: '/download/:phone/:id',
    name: '下载报告',
    component: DownloadReport,
  },
  {
    path: '/downloadpdf/:taskId/:uid/:cId',
    name: '下载报告',
    component: DownloadReportPdf,
  },
  {
    path: '/channeltest',
    name: '精准医疗test',
    component: ReportChannelUserData,
    // component: Demo,
  },
  {
    path: '/channelpubhead',
    name: '精准医疗',
    component: ReportChannelPublicHeader,
  },
  {
    path: '/channelpubhead',
    name: '精准医疗',
    component: ReportChannelPublicHeader,
  },
  {
    path: '/phyexam-upload',
    name: '体检报告解读',
    component: PhysicalEaxmReportPhoneUpload,
  },
  {
    path: '/phyexam-upload-ph',
    name: '体检报告解读',
    component: PhysicalEaxmReportPhoneUpload,
  },
  {
    path: '/phyexam-upload-model',
    name: '体检报告解读',
    component: PhysicalEaxmReportPhoneUpload,
  },
  //详细推荐医生版本
  {
    path: '/phyexam-upload-doc-detail',
    name: '体检报告解读',
    component: PhysicalEaxmReportPhoneUpload,
  },
  // 体检报告解读小助手
  {
    path: '/phyexam-assistant',
    name: '体检报告解读小助手',
    component: PhyexamAssistant,
  },
  // 体检报告解读小助手进度解析
  {
    path: '/phyexam-assistant-progress',
    name: '体检报告解读小助手',
    component: PhyexamAssistantProgress,
  },
  // 体检报告解读小助手解析结果页面
  {
    path: '/phyexam-assistant-report',
    name: '体检报告分析结果',
    component: PhyexamAssistantReport,
  },
  {
    path: '/phyexamreport/:uid',
    name: '体检报告分析结果',
    component: PhysicalEaxmReport,
  },
  // 固定中医理疗建议的体检报告解读结果报告上传页
  {
    path: '/phyexam-upload-med-fix',
    name: '体检报告解读',
    component: PhysicalEaxmReportUploadChnMedFix,
  },
  // 固定中医理疗建议的体检报告解读结果展示页
  {
    path: '/phyexamreport_chn_med/:uid',
    name: '体检报告分析结果',
    component: PhysicalEaxmReportChnMedFix,
  },
  // 真实中医理疗建议的体检报告解读结果报告上传页
  {
    path: '/phyexam-upload-med-chn-adv',
    name: '体检报告解读',
    component: PhysicalEaxmReportUploadChnMed,
  },
  // 真实中医理疗建议的体检报告解读结果展示页
  {
    path: '/phyexamreport_chn_med_adv/:uid',
    name: '体检报告分析结果',
    component: PhysicalEaxmReportChnMed,
  },
  {
    path: '/phyexam_report_cb',
    name: '体检报告分析结果',
    component: PhysicalEaxmReportCallback,
  },
  // 用于对接医院，展示结果页面
  {
    path: '/phyexam_report_enc_id_hos',
    name: '体检报告分析结果',
    component: PhysicalEaxmReportHos,
  },
  {
    path: '/phyexam_upload_cb',
    name: '体检报告解读',
    component: PhysicalEaxmUploadCallBack,
  },

  {
    path: '/error/:errContent',
    name: '精准医疗',
    component: ErrorPage,
    // component: Demo,
  },
  {
    path: '/chnError/:errContent',
    name: '中医问诊',
    component: ChnErrorPage,
  },
  //中医问诊
  {
    path: '/chnmedrepupload',
    name: '中医问诊',
    component: ChnMedReportUpload,
  },
  {
    path: '/chnmedrepview/:uid',
    name: '中医问诊报告',
    component: ChnMedReportView,
  },
  //中医问诊-固定结果展示用
  {
    path: '/chnmedrepuploadfix',
    name: '中医问诊',
    component: ChnMedReportUploadFix,
  },
  {
    path: '/chnmedrepviewfix/:uid',
    name: '中医问诊报告',
    component: ChnMedReportViewFix,
  },
  {
    path: '/chnmedrepuploadencsrc',
    name: '中医问诊',
    component: ChnMedReportUploadEncsrc,
  },
  {
    path: '/chnmedrepviewencsrc',
    name: '中医问诊报告',
    component: ChnMedReportViewEncsrc,
  },
  {
    path: '/chnmedreportviewpdf/:taskId/:uid/:cId',
    name: '中医问诊报告',
    component: ChnMedReportViewPdf,
  },
  {
    path: '/chnmedreportviewfixpdf/:taskId/:uid/:cId/:print',
    name: '中医问诊报告',
    component: ChnMedReportViewFixPdf,
  },
  //健康饮食
  {
    path: '/diet',
    name: '健康饮食',
    component: Diet,
  },
  {
    path: '/dietreport/:uid',
    name: '健康饮食',
    component: DietReport,
  },
  {
    path: '/dietdemo',
    name: '健康饮食',
    component: DietDemo,
  },
  {
    path: '/dietreportdemo/:uid',
    name: '健康饮食',
    component: DietReportDemo,
  },
  {
    path: '/takephoto',
    name: '拍照',
    component: TakePhoto,
  },
  {
    path: '/identifying',
    name: '正在识别',
    component: Identifying,
  },
  {
    path: '/dietreportdetail/:uid',
    name: '识别结果',
    component: DietReportDetail,
  },
  //华医生
  {
    path: '/doctorhuaIndex',
    name: '华医生',
    component: doctorhuaIndex,
  },
  {
    path: '/doctorhuaChat',
    name: '华医生',
    component: doctorhuaChat,
  },
  {
    path: '/intelligentCall',
    name: '智能医生语音通话',
    component: intelligentCall,
  },
  {
    path: '/historyRecord',
    name: '对话记录',
    component: historyRecord,
  },
  //心理治疗
  {
    path: '/psychoChat',
    name: 'AI心理治疗',
    component: psychoChat,
  },
  {
    path: '/psychoHistoryRecord',
    name: '对话记录',
    component: psychoHistoryRecord,
  },
  {
    path: '/psychoCall',
    name: 'AI心理治疗语音通话',
    component: psychoCall,
  },
  //智能医生
  {
    path: '/smartDoctorChat',
    name: '智能医生',
    component: smartDoctorChat,
  },
  {
    path: '/smartDoctorHistoryRecord',
    name: '对话记录',
    component: smartDoctorHistoryRecord,
  },
  {
    path: '/smartDoctorCall',
    name: '智能医生语音通话',
    component: smartDoctorCall,
  },
  //常见病知识解答
  {
    path: '/commonDiseaseChat',
    name: '常见病知识解答',
    component: commonDiseaseChat,
  },
  {
    path: '/commonDiseaseHistoryRecord',
    name: '对话记录',
    component: commonDiseaseHistoryRecord,
  },
  {
    path: '/commonDiseaseCall',
    name: '常见病知识解答语音通话',
    component: commonDiseaseCall,
  },
  //AI药店执业药师
  {
    path: '/pharmacistChat',
    name: 'AI药店执业药师',
    component: pharmacistChat,
  },
  {
    path: '/pharmacistHistoryRecord',
    name: '对话记录',
    component: pharmacistHistoryRecord,
  },
  {
    path: '/pharmacistCall',
    name: 'AI药店执业药师语音通话',
    component: pharmacistCall,
  },
  //智能导诊
  {
    path: '/guidanceChat',
    name: '智能导诊',
    component: guidanceChat,
  },
  {
    path: '/guidanceHistoryRecord',
    name: '对话记录',
    component: guidanceHistoryRecord,
  },
  {
    path: '/guidanceCall',
    name: '智能导诊语音通话',
    component: guidanceCall,
  },
  //药品知识回答
  {
    path: '/drugKnowledgeChat',
    name: '药品知识回答',
    component: drugKnowledgeChat,
  },
  {
    path: '/drugKnowledgeHistoryRecord',
    name: '对话记录',
    component: drugKnowledgeHistoryRecord,
  },
  {
    path: '/drugKnowledgeCall',
    name: '药品知识回答语音通话',
    component: drugKnowledgeCall,
  },
  //新版精准医疗
  {
    path: '/precisionMedicine',
    name: '精准医疗',
    component: PrecisionMedicine,
  },
  {
    path: '/precisionMedicineConfirmUpload',
    name: '确认上传',
    component: PrecisionMedicineConfirmUpload,
  },
  {
    path: '/precisionMedicineAnalysis',
    name: '精准医疗',
    component: Analysis,
  },
  {
    path: '/precisionMedicineGuidelineReport/:uid/:generate',
    name: '精准就医指引报告',
    component: GuidelineReport,
  },
  // web 精准医疗
  {
    path: '/webPrecisionMedicine',
    name: '精准医疗',
    component: WebPrecisionMedicine,
  },
  {
    path: '/webPrecisionMedicineReport/:uid',
    name: '精准就医指引报告',
    component: WebPrecisionMedicineReport,
  },
  //对话生成病例
  {
    path: '/dialogGenerationCase',
    name: '对话生成病例',
    component: DialogGenerationCase,
  },
  // 病例分析
  {
    path: '/caseAnalysis',
    name: '病例分析',
    component: CaseAnalysis,
  },
  // 关键医学信息提取
  {
    path: '/keyMedicalInformationExtraction',
    name: '关键医学信息提取',
    component: KeyMedicalInformationExtraction,
  },
  {
    path: '/recommendInspect',
    name: '推荐检查检验',
    component: RecommendInspect,
  },
  // 健康饮食
  {
    path: '/healthyDiet',
    name: '健康饮食',
    component: HealthyDiet,
  },
  // 健康饮食分析进度
  {
    path: '/healthyDietIdentifying',
    name: '正在识别',
    component: HealthyDietIdentifying,
  },
  // 健康饮食分析详情
  {
    path: '/healthyDietReport/:uid',
    name: '识别结果',
    component: HealthyDietReport,
  },
  //华医生web版
  {
    path: '/doctorhuaWeb',
    name: '华医生',
    component: DoctorhuaWeb,
    redirect: '/doctorhuaWeb/introduce',
    children: [
      {
        path: 'introduce',
        name: '华医生介绍',
        component: DoctorhuaIntro,
      },
      {
        path: 'chat/:id',
        name: '华医生对话',
        component: DoctorhuaChat,
      },
    ],
  },
  //新版中医问诊
  {
    path: '/chnmedrepuploadnew',
    name: '中医问诊',
    component: ChnMedReportUploadNew,
  },
  {
    path: '/chnmedrepviewnew/:uid',
    name: '中医问诊报告',
    component: ChnMedReportViewNew,
  },
  //面诊舌诊
  {
    path: '/faceAndRongueExamination',
    name: '面诊舌诊',
    component: FaceAndRongueExamination,
  },
  {
    path: '/faceAndRongueExaminationTakePhoto',
    name: '面诊舌诊',
    component: FaceAndRongueExaminationTakePhoto,
  },
  {
    path: '/faceAndRongueExaminationProgress',
    name: '面诊舌诊',
    component: FaceAndRongueExaminationProgress,
  },
  {
    path: '/faceAndRongueExaminationReport',
    name: '面诊舌诊分析结果',
    component: FaceAndRongueExaminationReport,
  },
  {
    path: '/faceAndRongueExaminationReportPrint/:uid',
    name: '面诊舌诊报告打印',
    component: FaceAndRongueExaminationReportPrint,
  },
  //华医生提取关键字跳转应用演示版本
  {
    path: '/logindrhua',
    name: '登录',
    component: LoginDrhua,
  },
  {
    path: '/registerdrhua',
    name: '注册',
    component: RegisterDrhua,
  },
  {
    path: '/changePwddrhua',
    name: '找回密码',
    component: ChangePwdDrhua,
  },
  {
    path: '/doctorhuaLink',
    name: '华医生',
    component: DoctorhuaLinkIndex,
  },
  {
    path: '/doctorhuaLinkChat',
    name: '华医生',
    component: DoctorhuaLinkChat,
  },
  {
    path: '/doctorhuaLinkRecord',
    name: '对话记录',
    component: DoctorhuaLinkRecord,
  },
  {
    path: '/',
    name: '精准医疗',
    component: ReportChannel,
  },
]
export default Router
